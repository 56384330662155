.token.punctuation {
  color: #999;
}
.token.boolean,
.token.number {
  color: #905;
}

.token.string {
  color: #690;
}

.token.operator {
  color: #9a6e3a;
  background: hsla(0, 0%, 100%, 0.5);
}

label,
.textarea {
  font-size: 0.8rem;
  letter-spacing: 1px;
}
.textarea {
  padding: 10px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
}

.label {
  display: block;
  margin-bottom: 10px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
